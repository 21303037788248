import styled from 'styled-components'
import React, { useState } from 'react'
import { rhythm } from '@utils/typography'
import { below } from '@utils/utilities'
import { motion } from 'framer-motion'

const HeroH1 = styled(motion.h1)`
  color: ${props => props.theme.secondary};
`
const HeroH1Stroke = styled(motion.h1)`
  content: ${props => props.textContent};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${props =>
    props.theme.isDark ? props.theme.brand.white : props.theme.secondary};
`
const MenuH1 = styled(motion.h1)`
  line-height: ${rhythm(2.25)};
  margin-bottom: 0;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${props => props.theme.primary};
  ${below.s`
  font-size: ${rhythm(1.8)};`}
  ${below.xs`
  font-size: ${rhythm(1.33)};`}
`
const MenuH1Stroke = styled(MenuH1)`
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  font-family: integralcf-heavy;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${props =>
    props.theme.isDark ? props.theme.primary : props.theme.brand.white};
`

const HeroHeader = ({ textContent, isMenuItem, primary, children }) => {
  const [isHovered, setHovered] = useState(false)
  function hover() {
    if (isHovered === false) {
      setHovered(true)
    }
  }
  function unhover() {
    if (isHovered === true) {
      setHovered(false)
    }
  }
  return (
    <div>
      {isMenuItem ? (
        <div
          css={`
            position: relative;
            overflow: visible;
            transform: translate3d(${rhythm(1 / 5)}, 0, 0);

            ${below.xs`
              transform:none;
            `}
          `}
        >
          <MenuH1
            className="menuHeroH1"
            textContent={textContent}
            whileHover={{
              color: primary,
            }}
            onHoverStart={hover}
            onHoverEnd={unhover}
          >
            {textContent}
          </MenuH1>

          <MenuH1Stroke
            initial={{
              transform: 'translate3d(0,0,0)',
              opacity: 0,
            }}
            animate={{
              transform: isHovered
                ? `translate3d(${rhythm(-1 / 6)}, ${rhythm(1 / 6)}, ${rhythm(
                    0
                  )})`
                : `translate3d(${rhythm(0)},${rhythm(0)},${rhythm(0)})`,
              opacity: isHovered ? 1 : 0,
            }}
            transition={{
              type: 'tween',
              ease: 'anticipate',
              duration: 0.66,
            }}
          >
            {textContent}
          </MenuH1Stroke>
        </div>
      ) : (
        <div
          style={{
            position: 'relative',
            overflow: 'visible',
            transform: `translate3d(${rhythm(1 / 5)}, 0, 0)`,
          }}
        >
          <HeroH1 className="heroH1" isMenuItem={isMenuItem}>
            {textContent}
          </HeroH1>
          <HeroH1Stroke className="heroH1Stroke" isMenuItem={isMenuItem}>
            {textContent}
          </HeroH1Stroke>
          <h6 className="h1subtitles">MOTION DESIGNER | ANIMATOR</h6>
        </div>
      )}
      {children}
    </div>
  )
}
export default HeroHeader
