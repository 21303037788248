import { ThemeManagerContext } from 'gatsby-styled-components-dark-mode'
import React, { useContext, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { ThemeProvider } from '@emotion/react'
import Navigation from '@components/nav/navigation'
import { ThemeToggler } from '@elements/buttons'
import GlobalStyle from '@styles/reset'
import { TransitionPortal } from 'gatsby-plugin-transition-link'
import { useSiteQuery } from '@queries/SITE_QUERY'
import { Flex } from 'rebass'
import ViewSwitch from '@elements/viewswitch'
import { AnimatePresence } from 'framer-motion'
import { withPrefix } from 'gatsby'
import { useAppContext } from '@context/context'

const Layout = withTheme(props => {
  const isHomepage = props.location.pathname === withPrefix('/')
  const { setUseViewSwitch, useViewSwitch } = useAppContext()
  useEffect(() => {
    if (isHomepage) {
      setUseViewSwitch(true)
    }
  }, [isHomepage, setUseViewSwitch])

  const themeContext = useContext(ThemeManagerContext)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => themeContext.toggleDark(true), [])
  const data = useSiteQuery()

  return (
    <ThemeProvider theme={props.theme}>
      <GlobalStyle theme={props.theme} />
      <TransitionPortal level="top">
        <ThemeToggler />
      </TransitionPortal>

      <AnimatePresence>
        <TransitionPortal level="top">
          {useViewSwitch && <ViewSwitch location={props.location} />}
        </TransitionPortal>
      </AnimatePresence>

      <main>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            minHeight: '100vh',
          }}
        >
          <Navigation
            data={data}
            theme={props.theme}
            location={props.location}
          />

          {props.children}
        </Flex>
      </main>
    </ThemeProvider>
  )
})

export default Layout
