import React from 'react'
import { TransitionPortal } from 'gatsby-plugin-transition-link'
import { useAppContext } from '@context/context'
import { Flex } from 'rebass'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import FullNav from './fullnav'
import TopNav from './topnav'

export default function Navigation(props) {
  const { data, theme, location } = props
  const isHomepage = location.pathname === withPrefix('/')

  const {
    isNavOpen,
    setNavOpen,
    menuColorDelay,
    setMenuColorDelay,
    viewWidth,
  } = useAppContext()

  const { primary, secondary, brand } = theme
  const { menuLinks } = data.site.siteMetadata
  let mobileWidth

  if (isMobile === false) {
    if (isHomepage) {
      mobileWidth = `${viewWidth * 100}px`
    } else {
      mobileWidth = `${viewWidth * 100 - 6}px`
    }
  } else {
    mobileWidth = `${viewWidth * 100}px`
  }

  return (
    <TransitionPortal level="top">
      <TopWrapper
        alignItems="center"
        justifyContent="space-between"
        width={mobileWidth}
        // initial={false}
        // animate={{
        //   width: mobileWidth,
        // }}
        // transition={{ delay: 0.75 }}
      >
        <TopNav
          isNavOpen={isNavOpen}
          setNavOpen={setNavOpen}
          primary={primary}
          secondary={secondary}
          white={brand.white}
          theme={theme}
          menuColorDelay={menuColorDelay}
          setMenuColorDelay={setMenuColorDelay}
        />

        <FullNav
          location={location}
          menuLinks={menuLinks}
          isNavOpen={isNavOpen}
          setNavOpen={setNavOpen}
          primary={primary}
          secondary={secondary}
          white={brand.white}
          theme={theme}
        />
      </TopWrapper>
    </TransitionPortal>
  )
}

// const TopWrapper = styled(motion.custom(Flex))``
const TopWrapper = styled(Flex)``
