import React from 'react'
import poweredBy from '@data/poweredby'
import styled from 'styled-components'
import { rhythm } from '@utils/typography'
import { motion } from 'framer-motion'
import { Flex } from 'rebass'

const PoweredByBar = () => {
  return (
    <PoweredByRowWrapper>
      <PoweredBy>POWERED BY</PoweredBy>
      <Divider />
      {poweredBy.map(powered => (
        <PoweredByLink
          key={powered.key}
          href={powered.url}
          initial={{
            scale: 1,
            opacity: 0.5,
          }}
          whileHover={{
            scale: 1.05,
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
        >
          {powered.icon}
        </PoweredByLink>
      ))}
    </PoweredByRowWrapper>
  )
}

const PoweredByRowWrapper = ({ children }) => {
  return (
    <>
      <PBFlex justifyContent="space-around" alignItems="center">
        {children}
      </PBFlex>
    </>
  )
}

const PBFlex = styled(Flex)`
  margin: 0 auto;
  max-width: 576px;
`
const PoweredBy = styled.span`
  font-family: made_tommy_regular;
  font-style: normal;
  font-weight: 300;
  font-size: ${rhythm(1 / 2.25)};
  letter-spacing: ${rhythm(1 / 8)};
  text-transform: uppercase;
`
const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${props => props.theme.primary};
  margin: 0 ${rhythm(1 / 2)};
`
const PoweredByLink = styled(motion.a)`
  color: ${props => props.theme.primary};
  margin: 0 ${rhythm(1 / 8)};
`
export default PoweredByBar
