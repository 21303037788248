import { graphql, useStaticQuery } from 'gatsby'

export const useRecentProjectsQuery = () => {
  const data = useStaticQuery(
    graphql`
      query RecentProjectsQuery {
        motionProjects: allMdx(
          filter: { frontmatter: { projectType: { eq: "motion" } } }
          limit: 3
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                projectType
              }
            }
          }
        }
        threeDVFX: allMdx(
          filter: { frontmatter: { projectType: { eq: "3D" } } }
          limit: 3
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                projectType
              }
            }
          }
        }
        misc: allMdx(
          filter: { frontmatter: { projectType: { eq: "misc" } } }
          limit: 3
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                projectType
              }
            }
          }
        }
        recents: allMdx(
          limit: 6
          filter: {
            frontmatter: {
              client: { ne: "Contact" }
              title: { ne: "2020 Reel" }
            }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  )
  return data
}
