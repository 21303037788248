import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { rhythm } from '@utils/typography'
import HeroHeader from '@elements/headings'
import TransitionPortal from 'gatsby-plugin-transition-link/components/TransitionPortal'
import FullscreenLogo from '@elements/fullscreenlogo'
import { useAppContext } from '@context/context'
import AnimationLink from '@elements/animationlink'
import { useRecentProjectsQuery } from '@queries/RECENTPROJECTS_QUERY'
import { below } from '@utils/utilities'
import { Flex, Box } from 'rebass'
import { navigate, withPrefix } from 'gatsby'
import SocialSidebar from './socialsidebar'
import PoweredByBar from './poweredbybar'

const mainTrans = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.77 }
const variants = {
  open: {
    y: `${0}%`,
    transition: {
      ...mainTrans,
      delayChildren: 0.5,
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
  closed: {
    y: '-100%',
    transition: {
      ...mainTrans,
      ease: [0.43, 0.13, 0.23, 0.96],
      delayChildren: 0.5,

      delay: 0.77,
    },
  },
}
const menuLinkVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      ...mainTrans,
      delayChildren: 0.15,
      staggerChildren: 0.05,
    },
  },
  closed: {
    opacity: 0,
    y: -125,
    transition: {
      ...mainTrans,
      delayChildren: 0.15,
      staggerChildren: 0.05,
    },
  },
}
const subVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      ...mainTrans,
    },
  },
  closed: {
    opacity: 0,
    y: 15,
    transition: {
      ...mainTrans,
    },
  },
}
const socialVariants = {
  open: {
    x: 0,
    transition: {
      ...mainTrans,

      duration: 1,
    },
  },
  closed: {
    x: 250,
    transition: {
      ...mainTrans,
    },
  },
}
export default function FullNav({
  menuLinks,
  primary,
  secondary,
  white,
  isNavOpen,
  setNavOpen,
  location,
}) {
  const data = useRecentProjectsQuery()

  const { edges: recentProjects } = data.recents
  const { showDebugs, viewportHeight, setGridView } = useAppContext()

  const isHomepage = location.pathname === withPrefix('/')
  return (
    <AnimatePresence>
      {isNavOpen && (
        <TransitionPortal level="middle">
          <MenuNav
            flexDirection="column"
            justifyContent="space-around"
            key="menunav"
            variants={variants}
            initial="closed"
            animate={isNavOpen ? 'open' : 'closed'}
            exit="closed"
            viewportHeight={viewportHeight}
          >
            {menuLinks.map(menuLink => {
              let item
              if (menuLink.key === 2) {
                item = (
                  <motion.li
                    key={menuLink.key}
                    custom={menuLink.key}
                    variants={menuLinkVariants}
                  >
                    <button
                      className="menucontactbutton"
                      type="button"
                      onClick={() => {
                        if (isNavOpen === true) {
                          setNavOpen(false)
                        } else if (isNavOpen === false) {
                          setNavOpen(true)
                        }
                        if (isHomepage) {
                          navigate(
                            '/#Contact',
                            { replace: true },
                            { state: { fromContactLink: true } }
                          )
                        } else {
                          navigate(
                            '/#Contact',
                            { replace: false },
                            { state: { fromContactLink: true } }
                          )
                        }
                      }}
                    >
                      <HeroHeader
                        isMenuItem
                        textContent={menuLink.name}
                        primary={primary}
                        secondary={secondary}
                        white={white}
                      />
                    </button>
                  </motion.li>
                )
              } else if (menuLink.key === 1) {
                item = (
                  <>
                    <motion.li
                      key={menuLink.key}
                      custom={menuLink.key}
                      variants={menuLinkVariants}
                    >
                      <AnimationLink
                        to={isHomepage ? '/projects' : menuLink.link}
                        activeClassName="menuActive"
                        partiallyActive
                        onClick={() => {
                          if (isNavOpen === true) {
                            setNavOpen(false)
                          } else if (isNavOpen === false) {
                            setNavOpen(true)
                          }
                          if (isHomepage) {
                            setGridView(true)
                          }
                        }}
                      >
                        <HeroHeader
                          isMenuItem
                          textContent={menuLink.name}
                          primary={primary}
                          secondary={secondary}
                          white={white}
                        />
                      </AnimationLink>
                      <SubFlex
                        flexDirection={['column', 'row', 'row', 'row']}
                        initial={{ height: `${0}%` }}
                        animate={{ height: `${100}%` }}
                        style={{
                          overflow: 'visible',
                          marginTop: `${rhythm(1 / 2)}`,
                        }}
                      >
                        <ProjectSubheadings
                          menuSubheadingTitle="Recent Projects"
                          projects={recentProjects}
                          showDebugs={showDebugs}
                          primary={primary}
                          secondary={secondary}
                          white={white}
                          isNavOpen={isNavOpen}
                          setNavOpen={setNavOpen}
                        />
                      </SubFlex>{' '}
                    </motion.li>
                  </>
                )
              } else {
                item = (
                  <motion.li
                    key={menuLink.key}
                    custom={menuLink.key}
                    variants={menuLinkVariants}
                  >
                    <AnimationLink
                      to={menuLink.link}
                      activeClassName="menuActive"
                      partiallyActive
                      onClick={() => {
                        if (isNavOpen === true) {
                          setNavOpen(false)
                        } else if (isNavOpen === false) {
                          setNavOpen(true)
                        }
                      }}
                    >
                      <HeroHeader
                        isMenuItem
                        textContent={menuLink.name}
                        primary={primary}
                        secondary={secondary}
                        white={white}
                      />
                    </AnimationLink>
                  </motion.li>
                )
              }
              return item
            })}

            <motion.li key="reel" custom={4} variants={menuLinkVariants}>
              <AnimationLink
                to="/"
                onClick={() => {
                  if (isNavOpen === true) {
                    setNavOpen(false)
                  } else if (isNavOpen === false) {
                    setNavOpen(true)
                  }
                  navigate(
                    '/#2020Reel',
                    { replace: true },
                    { state: { fromReelLink: true } }
                  )
                }}
                state={{ fromReelLink: true }}
                partiallyActive={false}
              >
                <HeroHeader
                  isMenuItem
                  textContent="Reel"
                  primary={primary}
                  secondary={secondary}
                  white={white}
                />
              </AnimationLink>
            </motion.li>
            <PoweredByBar />
          </MenuNav>
          <SocialSidebar variants={socialVariants} isNavOpen={isNavOpen} />

          <FullscreenLogo />
        </TransitionPortal>
      )}
    </AnimatePresence>
  )
}
const ProjectSubheadings = ({
  projects,
  menuSubheadingTitle,
  isNavOpen,
  setNavOpen,
}) => {
  return (
    <Box width="100%">
      <Flex flexDirection="row" flexWrap="wrap" key={menuSubheadingTitle}>
        <Box width="100%">
          <h5 className="menusubheading">{menuSubheadingTitle}</h5>
        </Box>
        {projects.map((project, index) => (
          <MotionBox
            variants={subVariants}
            custom={index}
            key={project.node.fields.slug}
            width={['100%', '33%']}
          >
            <AnimationLink
              to={project.node.fields.slug}
              className="menusublinks"
              onClick={() => {
                if (isNavOpen === true) {
                  setNavOpen(false)
                } else if (isNavOpen === false) {
                  setNavOpen(true)
                }
              }}
            >
              <motion.h6
                initial={{ opacity: 0.5, scale: 1 }}
                className="menusubprojects"
                whileHover={{
                  opacity: 1,
                  scale: 1.05,
                }}
                style={{ originX: 0 }}
              >
                {project.node.frontmatter.title}
              </motion.h6>
            </AnimationLink>
          </MotionBox>
        ))}
      </Flex>
    </Box>
  )
}

const MotionBox = styled(motion.custom(Box))``
const SubFlex = styled(motion.custom(Flex))``
const MenuNav = styled(motion.custom(Flex))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  height: ${props => props.viewportHeight};
  background: ${props => props.theme.secondary};
  margin: 0;
  list-style-type: none;
  padding: ${rhythm(2)} ${rhythm(2)} ${rhythm(1.5)} ${rhythm(2)};
  margin: auto;
  align-items: center;

  ${below.xs`
  padding:${rhythm(2.66)} ${rhythm(1)} ${rhythm(1.5)} ${rhythm(1)};
  `}
  li {
    list-style: none;
    width: 100%;
    font-size: ${rhythm(1.15)};
    position: relative;
    width: 100%;
    ${below.xs`
      text-align:center;
    `}
    a {
      color: ${props => props.theme.primary};
      text-decoration: none;
      position: relative;
      transition: all 0.33s cubic-bezier(0.15, 0.65, 0.4, 1);
      opacity: 0.66;
      width: 100%;
    }
  }
`
