import React, { useEffect, createContext, useContext, useState } from 'react'
import { useViewportScroll, useAnimation } from 'framer-motion'
import { ThemeContext } from 'styled-components'
import { ThemeManagerContext } from 'gatsby-styled-components-dark-mode'
import { useWindowSize } from 'react-use'
import useScrollDirection from '@hooks/useScrollDirection'

const defaultValues = {
  isNavOpen: false,
  setNavOpen: () => {},
}

export const GlobalContext = createContext(defaultValues)

export const ContextProvider = ({ children }) => {
  const contactViewSwitchControls = useAnimation()
  const [isGridView, setGridView] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isNavOpen, setNavOpen] = useState(false)
  const { scrollY } = useViewportScroll()
  const direction = useScrollDirection()
  const yPos = scrollY.current
  const themeContext = useContext(ThemeManagerContext)
  const { isDark } = themeContext
  const [showDebugs, setShowDebugs] = useState(false)
  const [menuAnimComplete, setMenuAnimComplete] = useState(false)
  const { width, height } = useWindowSize()
  const viewHeight = height * 0.01
  const viewportHeight = `${viewHeight * 100}px`
  const viewWidth = width * 0.01
  const viewportWidth = `${viewWidth * 100}px`
  const [menuColorDelay, setMenuColorDelay] = useState(0)
  const [lockFPScroll, setLockFPScroll] = useState(true)
  const [useViewSwitch, setUseViewSwitch] = useState(true)
  const [enteringVideoHeight, setEnteringVideoHeight] = useState(0)
  const [enteringVideoWidth, setEnteringVideoWidth] = useState(0)
  const [enteringGridItemDimensions, setEnteringGridItemDimensions] = useState({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  })
  const [activeFPSlide, setActiveFPSlide] = useState('')
  useEffect(() => {
    if (isNavOpen) {
      setMenuColorDelay(1)
    } else {
      setMenuColorDelay(0)
    }
  }, [isNavOpen])
  return (
    <GlobalContext.Provider
      value={{
        contactViewSwitchControls,
        isGridView,
        setGridView,
        enteringGridItemDimensions,
        setEnteringGridItemDimensions,
        enteringVideoWidth,
        setEnteringVideoWidth,
        activeFPSlide,
        setActiveFPSlide,
        isModalOpen,
        isNavOpen,
        isDark,
        setModalOpen,
        setNavOpen,
        themeContext,
        showDebugs,
        setShowDebugs,
        menuAnimComplete,
        setMenuAnimComplete,
        scrollY,
        yPos,
        width,
        height,
        viewWidth,
        viewHeight,
        viewportHeight,
        viewportWidth,
        menuColorDelay,
        setMenuColorDelay,
        direction,
        lockFPScroll,
        setLockFPScroll,
        useViewSwitch,
        setUseViewSwitch,
        enteringVideoHeight,
        setEnteringVideoHeight,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useAppContext = () => useContext(GlobalContext)
export const useTheme = () => useContext(ThemeContext)
