module.exports = [{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"primary":"#FFF68B","secondary":"#09141F","menuColor":"#09141F","brand":{"midnight":"#09141F","midnightlite":"#002533","midnightlitest":"#22394D","midnightXTlite":"#406b91","midnightSuperLite":"#CDDBE9","yellow":"#FFF68B","darkgrey":"#818D92","litegrey":"#F2F4F5","white":"#ffffff"},"breakpoints":["36em","48em","64em","75em"]},"dark":{"primary":"#09141F","secondary":"#FFF68B","menuColor":"#ffffff","brand":{"midnight":"#09141F","midnightlite":"#002533","midnightlitest":"#22394D","midnightXTlite":"#406b91","midnightSuperLite":"#CDDBE9","yellow":"#FFF68B","darkgrey":"#818D92","litegrey":"#F2F4F5","white":"#ffffff"},"breakpoints":["36em","48em","64em","75em"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":800}},{"resolve":"gatsby-remark-video","options":{"width":"100%","height":"auto","preload":"auto","muted":true,"autoplay":true,"playsinline":true,"controls":false,"loop":true}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-G8N6FFJGKC","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ahoag.media","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andrew Hoag - Motion Designer & Animator","short_name":"A Hoag Media","start_url":"/","background_color":"#09141F","theme_color":"#09141F","display":"standalone","icon":"./static/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"cd9fa2c6749225220440178a22578379"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index/","/projects","/projects/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
