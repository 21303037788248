import { createGlobalStyle } from 'styled-components'
import { above, below } from '@utils/utilities'
import { rhythm } from '@utils/typography'

const GlobalStyle = createGlobalStyle`
.projectimageportal{
  width:100vw !important;
  height:100vh !important;

}
.contactBox {
  h2 {
    font-size: ${rhythm(1 / 2)};
    font-family: made_tommy_bold;
      text-transform: uppercase;
      letter-spacing: ${rhythm(0.05)};
    span > a{
      color:${props => props.theme.secondary};
    }
  }
}
button {
  position: relative;
  display: flex;
  overflow: visible;
  width:100%;
  height:100%;
  background:transparent;
  border:0 transparent;
  font:inherit;
}
.homepageSidecard {
  overflow:hidden;
}
::-webkit-scrollbar-track {
  background-color: ${props => props.theme.brand.darkgrey}
  }
::-webkit-scrollbar {
	width: 8px;
	background-color: ${props => props.theme.primary};

  }
::-webkit-scrollbar-thumb {	
	background-color: ${props => props.theme.secondary};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  * {
    -webkit-overflow-scrolling: touch;
    ;
  }
  body,
  html {
    margin: 0;
    padding: 0;
    pointer-events: all;
    overflow-x: hidden;
  }
  html {
    box-sizing: border-box;
    overflow: unset;
    
    ${below.xs`
    font-size:16px;  
    `}
    ${above.xs`
    font-size:18px;  
    `}
  

  }
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-height: 100vh;
    
  }
  main {
    height: 100%;
    margin: auto;
    background: ${props =>
      props.theme.isDark
        ? props.theme.primary
        : props.theme.brand.darkgrey} !important;
  };
  
  footer {
    width: 100%;
  }
  .projectbreakdown {
    background: ${props =>
      props.theme.isDark
        ? props.theme.primary
        : props.theme.brand.litegrey} !important;
  };
    p {
      
      a {
        text-decoration:underline;
        color:${props => props.theme.secondary};
      }
    }
    hr {
      background:transparent;
    }
    
  }
  h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-rendering: optimizeLegibility;
      vertical-align: baseline;
    }

    h1{
      color: ${props => props.theme.secondary}; 
    }
    h2,h3,h4,h5,h6{
      color: ${props =>
        !props.theme.isDark ? props.theme.secondary : props.theme.brand.white};
    }
  
    p {
      font-family: made_tommy_light;
      font-size:15px;
      color: ${props =>
        !props.theme.isDark ? props.theme.secondary : props.theme.brand.white};
        ${below.xs`
        font-size:14px;
        `}
    }
  pre code {
    background-color: ${props => props.theme.primary};
    color: white;
    border: 1px solid #999;
    display: block;
    padding: ${rhythm(1.666)};
    white-space: pre-wrap;
    margin-bottom: ${rhythm(1)};
  }
  a {
    color: ${props =>
      props.theme.isDark ? props.theme.litegrey : props.theme.primary};
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
  }
  .firstName{
    &:after {
      content: 'ANDREW';
      -webkit-text-stroke-color: ${props =>
        props.theme.isDark
          ? props.theme.brand.white
          : props.theme.secondary} !important;

    }
  }
  .lastName{
    &:after {
      content: 'HOAG';
      -webkit-text-stroke-color: ${props =>
        props.theme.isDark
          ? props.theme.brand.white
          : props.theme.secondary} !important;

    }
  .instalink {
  }
      a{font-family: made_tommy_bold;
    font-style: normal;
    font-weight: bold;
color: ${props =>
  props.theme.isDark ? props.theme.litegrey : props.theme.primary};
    line-height: ${rhythm(1.2)};
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    text-align: center;}
    }
  blockquote {
    border-left: 5px solid ${props => props.theme.primary};
    padding-left: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-style: italic;
    p {
      line-height: 1.3 !important;
    }
  }
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  [role="button"] {
    cursor: pointer;
  }
  a,
  a,
  area,
  button,
  [role="button"],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
    background-color: ${props => props.theme.primary};
  }
  caption {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: ${props => props.theme.primary};
    text-align: center;
    caption-side: bottom;
  }
  th {
    text-align: left;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  input,
  button,
  select,
  textarea {
    line-height: inherit;
  }
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  input[type="search"] {
    -webkit-appearance: none;
  }
  output {
    display: inline-block;
  }
  .innerwrap{
    max-width:960px;
    margin:0 auto;
 

    ${below.m`
    max-width: 576px;
    `}
 
  }
  .layout {
    min-height: 100vh;
  }
  .h1subtitles {
    font-family: made_tommy_bold;
    font-style: normal;
    font-weight: bold;
    font-size: ${rhythm(1 / 2)};
    line-height: ${rhythm(1)};
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: ${props =>
      !props.theme.isDark
        ? props.theme.brand.midnightlite
        : props.theme.brand.white};
    
  }
  .heroH1, .menuHeroH1 { 
  font-family: integralcf-heavy;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  position: relative;
  z-index: 10;
 
  }
.navoffset {
  margin-top:${rhythm(2)};
  margin-bottom:${rhythm(2)};
}
  .heroH1Stroke {
    font-family: integralcf-heavy;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: transparent;
  position: absolute;
  z-index: -10;
  top: 0;
  transform: translate3d(${rhythm(-1 / 5)}, ${rhythm(1 / 5)}, 0);

  }
  .menuHeroH1Stroke{
    opacity:0;
  }
 .menucontactbutton {
   padding: 0;
    div {
      ${below.xs`
      margin:0 auto;
      `}
    }
  } 
  .menuActive {
    
    .menuHeroH1{
      color:${props => props.theme.primary};
    }
    div > div > h1:last-of-type {
  
      font-family: integralcf-heavy;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: transparent;
  position: absolute;
  z-index: -10;
  top: 0;
  opacity:1 !important;
  transform: translate3d(${rhythm(-1 / 8)}, ${rhythm(1 / 8)}, ${rhythm(
  0
)}) !important;
}
  }
  .topnavbuttons {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 ${rhythm(1 / 2)};
    height: ${rhythm(2)};
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin:0 auto;
  }

  .centerflex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .themetoggler {
    transform: rotateZ(90deg);
    position:fixed;
    right:0;
    bottom:0;

    }
    .viewswitch {
    position:fixed;
    bottom:-8px;
    left:50%;
    margin-left:-40px;
    .viewtxt {
      text-align:center;
      margin:${rhythm(0.2)} 0;
      display: flex;
      height:0.69813rem;
      overflow:hidden;
      flex-wrap:nowrap;
      white-space:nowrap;
      font-family:'made_tommy_light';
      div {
        display:flex;
        flex-direction:column;
        margin-left:${rhythm(0.1)};
        height:inherit;
        font-size:inherit;
        span{
          height:inherit;
          font-size:inherit;
          color:${props => props.theme.secondary};
          &:first-of-type{text-align:center;}
          &:last-of-type{
        text-align:right;
      }
      }
 
    }
    }
    }
    .debugs {
      position:fixed;
      bottom: 0;
      right:0;
      width:${rhythm(5)};
      height:${rhythm(1)};
      color:purple;
      background:green;
      display:flex;
      flex-direction:row;
      color:white;
      font-size:${rhythm(1 / 2)};
    }
    .menusubheading{
      font-family: made_tommy_medium;
      font-style: normal;
      font-weight: 500;
      font-size: ${rhythm(1 / 2)};
      text-transform: uppercase;
      line-height: 25px;
      color: ${props => props.theme.primary};
           
      margin-bottom: ${rhythm(0.5)} !important;

    }
    .tl-edges{
      width: 100% !important;
    height: 100% !important;
    }
    .tl-wrapper {
      width: 100% !important;
    height: 100% !important;
    }
    .tl-wrapper-status--exiting {
      &:before {
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
      }
    }
    .menusublinks {
      position:relative;
      
      .menusubprojects {
      font-family: made_tommy_regular;
      font-style: normal;
      font-weight: 400;
    
      margin-bottom: ${rhythm(0.7)};
      letter-spacing:${rhythm(0.005)};
      padding-left:${rhythm(1 / 4)};
      text-transform: uppercase;
      color:${props => props.theme.primary};
    }
    }
    .image-container {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}
    .parallaxOverlay {
      top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
    }
    .spaceboihero {
      position:absolute;
      top:0%;
      left:0%;

      height:700px;
      overflow:visible;
      max-height:700px;
      z-index:-10;
      display:flex;
      transform: translate3d(-67px,-80px,0px);

      #spaceWrapper {
        width:100%;
        height:100%;
       z-index:-100;
      }
    }

    /* REACT FULLPAGE STYLES */

    #fp-nav.fp-right {
      right:${rhythm(1 / 2)} !important;
    }
    #fp-nav ul li a span {
      background:${props => props.theme.menuColor} !important;
      opacity:.5;

    }
    #fp-nav ul li a.active span {
      background:${props => props.theme.secondary} !important;
      opacity:1;
    }
    .fp-tooltip {
      font-family: made_tommy_regular !important;
    
      font-style: normal !important;
      color: ${props => props.theme.secondary} !important;
    }

  /* FORM STYLES */

  .contactform {
    form {
    display: flex;
    box-sizing:  border-box;
    margin: 0;
    min-width: 345px;
    border: 1px solid ${props => props.theme.secondary};
    position: relative;
    flex-direction:  column;
    align-items: stretch;
    width: 75%;
    margin: 0 auto;
    background-color: ${props => props.theme.primary};
    padding: ${rhythm(1)};
    }
    .contactbutton {
      height:100px;
      width:100px;
      padding:0;
      margin:0 auto;
      max-width:100px;
      font-family: made_tommy_bold;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
    max-height: ${rhythm(1.5)};
  border: 1px solid ${props => props.theme.secondary};
  background-color: ${props => props.theme.secondary};
  overflow: visible;
  box-sizing: content-box;
    span {
      margin: 0 auto;
      color: ${props => props.theme.primary};
      z-index: 100;
      font-size: ${rhythm(1 / 2)};
    }
    }
  }
label {
  font-size:${rhythm(1 / 1.5)} !important;
  font-family: made_tommy_regular;
  letter-spacing: 3px;
  padding-bottom:${rhythm(1 / 2)};
  text-transform: uppercase;
  color:${props => props.theme.secondary} !important;
  ${below.xs`
  font-size:${rhythm(0.5)} !important;
  `}
}
  
input, textarea {
  font-family: made_tommy_light;
  color:${props => props.theme.brand.primary} !important;
}

 `
export default GlobalStyle
