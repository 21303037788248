import styled, { ThemeContext } from 'styled-components'
import React, { useContext, useState } from 'react'
import { rhythm } from '@utils/typography'
import { motion } from 'framer-motion'
import { ThemeManagerContext } from 'gatsby-styled-components-dark-mode'
import ToggleButton from 'react-toggle-button'
import { FiSun, FiMoon } from 'react-icons/fi'
import { useAppContext } from '@context/context'
import { Flex, Box } from 'rebass'
import AnimationLink from '@elements/animationlink'

const ButtonWrapper = styled(motion.div)`
  width: ${props => props.width};
  height: ${props => props.height};
  max-width: ${props => props.maxWidth};
  max-height: ${rhythm(1.5)};
  border: 1px solid ${props => props.theme.secondary};
  background-color: transparent;
  overflow: visible;
  box-sizing: content-box;
  a,
  button {
    font-family: made_tommy_bold;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    text-align: center;
    span {
      margin: 0 auto;
      z-index: 100;
      font-size: ${rhythm(1 / 2)};
    }
  }
`

export const Button = ({
  ariaLabel,
  buttonText,
  height,
  isLink,
  linkTo,
  maxWidth,
  onClick,
  state,
  type,
  useButton,
  dataAnchor,
  appearAfter,
  width,
}) => {
  const themeContext = useContext(ThemeContext)
  const [isButtonHovered, setButtonHovered] = useState(false)

  return (
    <ButtonWrapper
      className="centerflex"
      initial={{ scale: 1 }}
      transition={{ type: 'tween' }}
      onHoverStart={() => setButtonHovered(true)}
      onHoverEnd={() => setButtonHovered(false)}
      onClick={onClick}
      width={width}
      height={height}
      maxWidth={maxWidth}
    >
      {isLink ? (
        <AnimationLink
          style={{ position: 'relative', display: 'flex', overflow: 'visible' }}
          to={linkTo}
          state={state}
          appearAfter={appearAfter}
          gridClass={dataAnchor}
        >
          <motion.span
            animate={{
              color: isButtonHovered
                ? `${themeContext.primary}`
                : `${themeContext.secondary}`,
            }}
          >
            {buttonText}
          </motion.span>
          <motion.div
            style={{
              position: 'absolute',
              height: '100%',
              background: `${themeContext.secondary}`,
              padding: 0,
              margin: 0,
              left: 0,
              top: 0,
              transform: 'scale3d(1.01,1.05,1.01)',
            }}
            animate={{ width: isButtonHovered ? `${100}%` : `${0}%` }}
            transition={{ type: 'tween' }}
          />
        </AnimationLink>
      ) : (
        <NoLinkButton
          as={useButton ? 'button' : 'a'}
          role={useButton && 'button'}
          type={type}
          ariaLabel={ariaLabel}
          onClick={onClick}
        >
          <motion.span
            animate={{
              color: isButtonHovered
                ? `${themeContext.primary}`
                : `${themeContext.secondary}`,
            }}
          >
            {buttonText}
          </motion.span>
          <motion.div
            style={{
              position: 'absolute',
              height: '100%',
              background: `${themeContext.secondary}`,
              padding: 0,
              margin: 0,
              left: 0,
              top: 0,
              transform: 'scale3d(1.01,1.05,1.01)',
            }}
            animate={{ width: isButtonHovered ? `${100}%` : `${0}%` }}
            transition={{ type: 'tween' }}
          />
        </NoLinkButton>
      )}
    </ButtonWrapper>
  )
}

const NoLinkButton = styled.div`
  position: relative;
  display: flex;
  overflow: visible;
`
export const ThemeToggler = () => {
  const themeContext = useContext(ThemeManagerContext)
  const themeActive = useContext(ThemeContext)
  const { isNavOpen, setMenuColorDelay } = useAppContext()
  const { isDark } = themeContext
  const ThemeToggleTrackStyles = {
    boxShadow: `0 0 0 1px ${themeActive.secondary}`,
    padding: 0,
  }
  const ToggleIconStyles = {
    transform: 'rotateZ(-90deg)',
    margin: '0 auto',
  }
  const LabelStyles = {
    padding: 0,
    height: '100%',
    left: 0,
  }
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="80px"
      height="80px"
      className="themetoggler"
    >
      <Box>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{
            opacity: isNavOpen ? 0 : 1,
            transition: { delay: !isNavOpen ? 1 : 0 },
          }}
        >
          <ToggleButton
            id="themeToggle"
            label="themeToggle"
            activeLabelStyle={LabelStyles}
            inactiveLabelStyle={LabelStyles}
            inactiveLabel={
              <FiSun style={ToggleIconStyles} color={themeActive.primary} />
            }
            activeLabel={
              <FiMoon style={ToggleIconStyles} color={themeActive.primary} />
            }
            value={isDark}
            onClick={() => setMenuColorDelay(0)}
            onToggle={() => themeContext.toggleDark(!isDark)}
            checked={themeContext.isDark}
            colors={{
              activeThumb: {
                base: `${themeActive.primary}`,
              },
              inactiveThumb: {
                base: `${themeActive.primary}`,
              },
              active: {
                base: `${themeActive.secondary}`,
              },
              inactive: {
                base: `${themeActive.secondary}`,
              },
            }}
            trackStyle={ThemeToggleTrackStyles}
            thumbStyle={{ transform: 'scale3d(.9,.9,.9)' }}
          />
        </motion.div>
      </Box>
    </Flex>
  )
}
