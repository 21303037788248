const React = require(`react`)
const { ContextProvider } = require(`./src/context/context`)
require(`intersection-observer`)
const Layout = require(`@components/layout`).default
require(`intersection-observer`)
require('./static/fonts/fonts.css')

exports.onInitialClientRender = () => {
  // eslint-disable-next-line global-require
  require('./static/fonts/fonts.css')
}

exports.wrapPageElement = ({ element, props }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Layout {...props}>{element}</Layout>
}

exports.onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    // eslint-disable-next-line no-console
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

exports.wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>
}

exports.onServiceWorkerUpdateReady = () => {
  // eslint-disable-next-line no-alert
  const answer = window.confirm(
    `YO! I've updated this stuff. Wanna reload and see the most recent site?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash) {
    return false
  }

  return true
}
