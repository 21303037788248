import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import fsLogo from '../images/logofs.svg'

export default function FullscreenLogo() {
  return (
    <>
      <FSWrapper
        initial={{ x: 500, opacity: 0 }}
        animate={{ x: 0, opacity: 0.66 }}
        exit={{ opacity: 0 }}
        transition={{
          delay: 0.15,
          type: 'tween',
          ease: 'anticipate',
          duration: 1.33,
        }}
      />
    </>
  )
}

const FSWrapper = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0; /* reset */
  background-image: url(${fsLogo});
  content: '';
  background-size: cover;
  background-position: 33vw center;
  background-repeat: no-repeat;
  pointer-events: none;
`
