import React from 'react'
import { rhythm } from '@utils/typography'

import {
  SiAdobecreativecloud,
  SiBlender,
  SiReact,
  SiGatsby,
  SiFigma,
  SiSketch,
} from 'react-icons/si'

const poweredBy = [
  {
    key: '1',
    name: 'Adobe',
    icon: <SiAdobecreativecloud size={`${rhythm(1 / 2)}`} />,
    url: 'https://creativecloud.adobe.com/',
  },
  {
    key: '2',
    name: 'Blender',
    icon: <SiBlender size={`${rhythm(1 / 2)}`} />,
    url: 'https://www.blender.org',
  },
  {
    key: '3',
    name: 'React',
    icon: <SiReact size={`${rhythm(1 / 2)}`} />,
    url: 'https://www.reactjs.org/',
  },
  {
    key: '4',
    name: 'GatsbyJS',
    icon: <SiGatsby size={`${rhythm(1 / 2)}`} />,
    url: 'https://www.gatsbyjs.org/',
  },
  {
    key: '5',
    name: 'Figma',
    icon: <SiFigma size={`${rhythm(1 / 2)}`} />,
    url: 'https://www.figma.com/',
  },
  {
    key: '6',
    name: 'Sketch',
    icon: <SiSketch size={`${rhythm(1 / 2)}`} />,
    url: 'https://www.sketch.com/',
  },
]

export default poweredBy
