import { css } from 'styled-components'
// eslint-disable-next-line import/no-cycle
import { baseFontSize } from '@utils/typography'

export const breakpoints = {
  xs: 576,
  s: 768,
  m: 1024,
  l: 1200,
}
export const vert = {
  xs: 800,
}
export const above = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / baseFontSize}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const below = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label] / baseFontSize}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const belowVert = Object.keys(vert).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-height: ${vert[label] / baseFontSize}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})
export const globalBreakpoints = Object.values(breakpoints)
