import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Menu = props => {
  const {
    isNavOpen,
    theme,
    white,
    secondary,
    primary,
    setNavOpen,
    transitions,
  } = props
  let menuColorAnim

  if (isNavOpen) {
    menuColorAnim = primary
  } else if (theme.isDark) {
    menuColorAnim = white
  } else {
    menuColorAnim = secondary
  }

  return (
    <StyledBurger
      onClick={() => {
        if (isNavOpen === true) {
          setNavOpen(false)
        } else if (isNavOpen === false) {
          setNavOpen(true)
        }
      }}
      aria-label="Open Navigation"
      key="menuburger"
      isNavOpen={isNavOpen}
    >
      <motion.div
        initial={{
          background: theme.menuColor,
          rotate: 0,
          x: 0,
          opacity: 1,
        }}
        animate={{
          rotate: isNavOpen ? 45 : 0,
          background: menuColorAnim,
        }}
        className="smallshadow"
        transition={transitions}
      />
      <motion.div
        initial={{
          background: theme.isDark ? white : secondary,
          rotate: 0,
          x: 0,
          opacity: 1,
        }}
        animate={{
          rotate: 0,
          x: isNavOpen ? -45 : 0,
          opacity: isNavOpen ? 0 : 1,
          background: menuColorAnim,
        }}
        className="smallshadow"
        transition={transitions}
      />
      <motion.div
        initial={{
          background: theme.isDark ? white : secondary,
          rotate: 0,
          x: 0,
          opacity: 1,
        }}
        animate={{
          rotate: isNavOpen ? -45 : 0,
          background: menuColorAnim,
        }}
        className="smallshadow"
        transition={transitions}
      />
    </StyledBurger>
  )
}

export default Menu

const StyledBurger = styled(motion.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 25%;
  z-index: 10;
  position: relative;
  z-index: 1;
  margin: 0;
  overflow: hidden;
  &:focus {
    outline: none;
  }
  div {
    width: 30px;
    height: 3px;
    position: relative;
    transform-origin: 10%;
  }
`
