import React from 'react'
import AnimationLink from '@elements/animationlink'
import Logo from '@elements/logo'
import { Flex } from 'rebass/styled-components'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Menu from './menu'

export default function TopNav({
  isNavOpen,
  primary,
  secondary,
  white,
  theme,
  setNavOpen,
  menuColorDelay,
}) {
  const transitions = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
    delay: menuColorDelay,
  }

  return (
    <>
      <TopNavItemWrapper
        isNavOpen={isNavOpen}
        primary={primary}
        secondary={secondary}
        transitions={transitions}
      >
        <AnimationLink
          to="/"
          onClick={() => {
            if (isNavOpen === true) {
              setNavOpen(false)
            }
          }}
          entryZIndex={2}
          exitZIndex={0}
          isMenu
          ariaLabel="Homepage"
        >
          <Logo
            aFill={isNavOpen ? primary : white}
            hFill={isNavOpen ? primary : secondary}
            primary={primary}
            secondary={secondary}
            white={white}
            isNavOpen={isNavOpen}
            theme={theme}
            transitions={transitions}
          />
        </AnimationLink>
      </TopNavItemWrapper>
      <TopNavItemWrapper
        isNavOpen={isNavOpen}
        primary={primary}
        secondary={secondary}
        transitions={transitions}
      >
        <Menu
          theme={theme}
          aFill={isNavOpen ? primary : white}
          primary={primary}
          secondary={secondary}
          white={white}
          isNavOpen={isNavOpen}
          setNavOpen={setNavOpen}
          transitions={transitions}
        />
      </TopNavItemWrapper>
    </>
  )
}
const TopNavItemWrapper = ({
  children,
  isNavOpen,
  primary,
  secondary,
  transitions,
}) => {
  return (
    <MotionFlex
      alignItems="center"
      justifyContent="center"
      width="64px"
      height="64px"
      initial={{ background: primary }}
      animate={{ background: isNavOpen ? secondary : primary }}
      transition={transitions}
    >
      {children}
    </MotionFlex>
  )
}

const MotionFlex = styled(motion.custom(Flex))``
