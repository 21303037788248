import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { useAppContext } from '@context/context'
import PropTypes from 'prop-types'
// eslint-disable-next-line react/display-name
const AnimationLink = React.forwardRef((props, ref) => {
  const { isNavOpen, setEnteringVideoHeight } = useAppContext()

  const {
    activeClassName,
    ariaLabel,
    children,
    className,
    id,
    onClick,
    state,
    to,
    style,
    appearAfter,
    entryLength,
    exitLength,
    entryZIndex,
    exitZIndex,
    isMenu,
  } = props
  const delayEntryTimer = isMenu ? 0.25 : 0
  const delayExitTimer = isMenu ? 0.2 : 0
  return (
    <TransitionLink
      activeClassName={activeClassName}
      aria-label={ariaLabel}
      className={className}
      title={ariaLabel}
      ref={ref}
      exit={{
        zIndex: exitZIndex || 1,
        length: exitLength || 1,
        delay: isNavOpen ? 0.53 : delayExitTimer,

        state: {
          fromAnimLink: false,
          ...state,
        },
        trigger: async pages => {
          // wait until we have access to both pages
          const entry = await pages.entry

          // here we can access both pages

          // You could measure the entry element here

          // start exit animation based on measurements if you want
          // wait for the entering page to become visible
          await entry.visible
          // the entering page is visible here.
          // if you want you can animate it now!
        },
      }}
      entry={{
        zIndex: entryZIndex || 2,
        length: entryLength || 1,
        delay: isNavOpen ? 0.5 : delayEntryTimer,
        appearAfter,
        state: {
          fromAnimLink: true,
          ...state,
        },
        trigger: async pages => {
          // wait until we have access to both pages
          const entry = await pages.entry
          const item = pages.node.querySelector('.video')

          if (item !== null) {
            const dimensions = item.getBoundingClientRect()

            setEnteringVideoHeight(dimensions.height)
          }
          // const gridItem = pages.node.querySelector(`.${activeFPSlide}`)
          // if (gridItem !== null) {
          //   const dimensions = gridItem.getBoundingClientRect()
          //   setEnteringGridItemDimensions({
          //     height: dimensions.height,
          //     left: dimensions.left,
          //     right: dimensions.right,
          //     top: dimensions.top,
          //     width: dimensions.width,
          //     x: dimensions.x,
          //     y: dimensions.y,
          //   })
          // }
          // here we can access both pages

          // You could measure the entry element here

          // start exit animation based on measurements if you want
          // wait for the entering page to become visible
          await entry.visible
        },
      }}
      state={{ fromAnimLink: true, ...state }}
      id={id}
      partiallyActive
      to={to}
      onClick={onClick}
      style={style}
    >
      {children}
    </TransitionLink>
  )
})

export default AnimationLink

AnimationLink.propTypes = {
  activeClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.string,
  to: PropTypes.string,
}.isRequired
