import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { useAppContext } from '@context/context'
import { Flex } from 'rebass'
import { ThemeContext } from 'styled-components'
import AnimationLink from './animationlink'

const ViewSwitch = ({ location }) => {
  const themeActive = useContext(ThemeContext)
  const {
    isNavOpen,
    setEnteringVideoHeight,
    isGridView,
    contactViewSwitchControls,
    setGridView,
  } = useAppContext()

  const locationSwitch = location.pathname === '/'
  useEffect(() => {
    if (locationSwitch) {
      setGridView(false)
    }
  }, [locationSwitch, setGridView])

  const contactAnimTrigger = location.hash === '#Contact'
  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{
        opacity: isNavOpen ? 0 : 1,
        transition: { delay: !isNavOpen ? 1 : 0 },
      }}
      style={{ background: locationSwitch && themeActive.primary }}
    >
      <AnimationLink
        to={locationSwitch ? '/projects' : '/'}
        onClick={() => {
          setEnteringVideoHeight(0)
          if (isGridView) {
            setGridView(false)
          } else {
            setGridView(true)
          }
          if (contactAnimTrigger) {
            contactViewSwitchControls.start({
              y: `${-100}vh`,
              transition: { duration: 0.5 },
            })
          } else {
            contactViewSwitchControls.stop()
          }
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          width="80px"
          height="80px"
          className="viewswitch"
          flexDirection="column"
        >
          <ViewIcon themeActive={themeActive} isGridView={isGridView} />
          <h6 className="viewtxt">
            <motion.span
              initial={{ x: 6 }}
              animate={{ x: isGridView ? 0 : 6 }}
              transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }}
            >
              View
            </motion.span>
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: isGridView ? -12 : 0 }}
              transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }}
            >
              <span>All</span>
              <span>Feat.</span>
            </motion.div>
          </h6>
        </Flex>
      </AnimationLink>
    </motion.div>
  )
}

const transition = {
  duration: 0.6,
  ease: [0.43, 0.13, 0.23, 0.96],
}
const iconVariants = {
  isGrid: {
    width: 7,
    height: 7,

    transition: { transition },
  },
  isCard: {
    width: 18,
    height: 18,
    transition: { transition },
  },
  scaleRect: {
    width: 0.1,
    height: 0.1,
    transition: { transition },
  },
}

const ViewIcon = ({ themeActive, isGridView }) => {
  const RectScaler = ({ x, y }) => {
    return <motion.rect width="7" height="7" x={x} y={y} />
  }
  return (
    <svg
      stroke={themeActive.brand.midnightXTlite}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={themeActive.brand.midnightXTlite}
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: themeActive.brand.midnightXTlite, margin: '0 auto' }}
    >
      <RectScaler x="14" y="3" />
      <RectScaler x="14" y="14" />
      <RectScaler x="3" y="14" />
      <motion.rect
        variants={iconVariants}
        initial="isCard"
        animate={!isGridView ? 'isGrid' : 'isCard'}
        x="3"
        y="3"
        fill={themeActive.primary}
      />
    </svg>
  )
}

export default ViewSwitch
