import { graphql, useStaticQuery } from 'gatsby'

export const useSiteQuery = () => {
  const data = useStaticQuery(
    graphql`
      query SiteQuery {
        site {
          siteMetadata {
            author
            description
            menuLinks {
              name
              link
              key
            }
            siteUrl
            social {
              username
            }
            title
            titleTemplate
            twitterUsername
          }
        }

        file(absolutePath: { regex: "/DefaultImage/" }) {
          defaultSocialCard: childImageSharp {
            resize(width: 1024, height: 1024, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
        allSitePage(
          filter: {
            isCreatedByStatefulCreatePages: { eq: true }
            path: { regex: "/^((?!404).)*$/" }
          }
        ) {
          nodes {
            path
          }
        }
      }
    `
  )
  return data
}
