import React from 'react'
import socialChannels from '@data/socialmedia'
import styled from 'styled-components'
import { rhythm } from '@utils/typography'
import { motion } from 'framer-motion'
import { Flex } from 'rebass'

const SocialSidebar = props => {
  const { variants, isNavOpen } = props
  return (
    <SocialSidebarWrapper
      flexDirection="column"
      justifyContent="space-evenly"
      variants={variants}
      initial="closed"
      exit="closed"
      animate={isNavOpen ? 'open' : 'closed'}
    >
      {socialChannels.map(socialChannel => (
        <div
          key={socialChannel.key}
          style={{
            padding: `${rhythm(1 / 4)}`,
            margin: '0 auto',
            display: 'flex',
          }}
        >
          <SocialSidebarLink
            href={socialChannel.url}
            initial={false}
            whileHover={{
              opacity: 1,
              scale: 1.05,
              transition: {
                duration: 0.2,
              },
            }}
            name={socialChannel.name}
            key={socialChannel.key}
          >
            {socialChannel.icon}
          </SocialSidebarLink>
        </div>
      ))}
    </SocialSidebarWrapper>
  )
}

const SocialSidebarWrapper = styled(motion.custom(Flex))`
  position: fixed;
  background-color: ${props => props.theme.primary};
  bottom: ${rhythm(4)};
  right: 0;
  border-radius: ${rhythm(1 / 2)} 0% 0% ${rhythm(1 / 2)};
  transform: translateX(${rhythm(1 / 6)});
  padding: 10px 5px 10px 10px;
`
const SocialSidebarLink = styled(motion.a)`
  color: ${props => props.theme.secondary};
  margin: 0 auto;
  opacity: 0.7;
  width: 16px;
  height: 16px;
`
export default SocialSidebar
