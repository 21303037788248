import Typography from 'typography'

export const baseFontSize = 16

const typography = new Typography({
  baseFontSize: `${baseFontSize}px`,
  includeNormalize: true,
  baseLineHeight: 1.666,
  scaleRatio: 3.313,
  headerFontFamily: [
    'integralcf-heavy',
    'made_tommy_bold',
    'Helvetica',
    'Arial',
    'normal',
  ],
  bodyFontFamily: [],
  bodyGray: 25,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    h6: {
      fontFamily: 'made_tommy_bold',
      textTransform: 'uppercase',
      letterSpacing: `${rhythm(0.05)}`,
    },
    p: {
      fontFamily: 'made_tommy_medium',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    li: {
      marginBottom: 0,
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}
export const { scale, rhythm, options } = typography

export default typography
