import React from 'react'
import {
  IoLogoVimeo,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoMdMail,
  IoIosCall,
} from 'react-icons/io'

const socialChannels = [
  {
    key: '1',
    name: 'Vimeo',
    icon: <IoLogoVimeo size="16px" />,
    url: 'https://www.vimeo.com/ahoagmedia/',
  },
  {
    key: '2',
    name: 'Twitter',
    icon: <IoLogoTwitter size="16px" />,
    url: 'https://www.twitter.com/ahoagmedia/',
  },
  {
    key: '3',
    name: 'LinkedIn',
    icon: <IoLogoLinkedin size="16px" />,
    url: 'https://www.linkedin.com/in/ahoagmedia/',
  },
  {
    key: '4',
    name: 'Instagram',
    icon: <IoLogoInstagram size="16px" />,
    url: 'https://www.instagram.com/ahoagmedia',
  },
  {
    key: '5',
    name: 'Email',
    icon: <IoMdMail size="16px" />,
    url: 'mailto:info@ahoagmedia.com',
  },
  {
    key: '6',
    name: 'Call',
    icon: <IoIosCall size="16px" />,
    url: 'tel:910.616.2727',
  },
]

export default socialChannels
